import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  // Add this line

  const [tools, setTools] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');

  useEffect(() => {
    fetch('/data.json')
      .then(response => response.json())
      .then(data => {
        setTools(data);
        const uniqueCategories = ['All', ...new Set(data.map(tool => tool.category))];
        setCategories(uniqueCategories);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const filteredTools = selectedCategory === 'All' 
    ? tools 
    : tools.filter(tool => tool.category === selectedCategory);

  return (
    <div className="App">
      <header>
        <h1>Personal AI Agent Directory</h1>
        <p>Discover and compare the best personal AI agents  to enhance your productivity and simplify your daily life.</p>
      </header>
      <main>
        <section className="filter">
          <h2>Find Your Perfect AI Agent</h2>
          <label htmlFor="category-select">Filter AI agents by category:</label>
          <select 
            id="category-select" 
            value={selectedCategory} 
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </section>
        <section className="tool-list">
          {filteredTools.map(tool => (
            <article key={tool.name} className="tool-card">
              <h3>{tool.name}</h3>
              <p>{tool.description}</p>
              <a href={tool.link} target="_blank" rel="noopener noreferrer">Explore this AI Agent</a>
              <span className="tag">{tool.category}</span>
            </article>
          ))}
        </section>
        {filteredTools.length === 0 && (
          <p className="no-tools">No AI agents found in this category. Try another category or check back later for updates to our AI assistant directory.</p>
        )}
      </main>
      <footer>
        <p>© 2024 Personal AI Agent Directory. Empowering you with the best AI assistants and search agents.</p>
      </footer>
    </div>
  );
}

export default App;